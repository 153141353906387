import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

import ExperienceCard from "./Experience";
import { experience, education } from "../util/experience";

export default function ExperienceEducation() {
	return (
		<>
			<MDBContainer>
				<MDBRow className='experienceSection my-3 px-md-5'>
					<MDBCol lg={6} className='px-xl-5'>
						<h4 data-aos='fade-down' className='text-center my-2 weight800 '>
							Experience
						</h4>
						{experience.map((item, index) => {
							return (
								<ExperienceCard
									key={index}
									id={index}
									position={item.position}
									date={item.date}
									description={item.description}
									skills={item.skills}
								/>
							);
						})}
					</MDBCol>
					<MDBCol lg={6} className='px-xl-5'>
						<h4 data-aos='fade-down' className='text-center my-2 weight800 '>
							Education
						</h4>
						{education.map((item, index) => {
							return (
								<ExperienceCard
									key={index}
									id={index}
									position={item.position}
									date={item.date}
									description={item.description}
								/>
							);
						})}
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
	);
}
