export const experience = [
	{
		position: "Junior Full Stack Software Developer",
		date: "March 2023 - Present",
		description: [
			"Assisting Senior Developer on adding feature for the system",
			"Debugging and fixing issues on the system",
			"Fixing front-end issues on the system",
			"Implementing reusable modules/components for the system",
			"Adding documentations and comments on the system for maintability",
			"Refactoring codes for better performance and readability",
		],
		skills: [
			"React JS",
			"AWS Amplify",
			"Javascript",
			"HTML",
			"CSS",
			"Bootstrap",
			"Git",
			"GitHub",
			"NPM",
		],
	},
	{
		position: "Park Maintenance Supervisor",
		date: "February 2022 - November 2022",
		description: [
			"Maintained and updated park facilities and equipment",
			"Ensured the park was safe and clean for visitors",
			"Managed a team of maintenance staff",
			"Coordinated with other departments to ensure efficient operations",
		],
		skills: [
			"Superior communication skills",
			"Leadership",
			"Teamwork",
			"Problem-solving",
			"Critical thinking",
			"Time management",
		],
	},
	{
		position: "Irrigation Engineer & Designer",
		date: "November 2018 - February 2022",
		description: [
			"Planned, designed, and implemented irrigation systems for various landscapes and golf courses",
			"Managed projects from start to finish, including client communication, site assessments, design development, cost estimating, and overseeing installation",
			"Collaborated with cross-functional teams including architects, contractors, and landscapers",
			"Utilized problem-solving and critical thinking skills to troubleshoot issues during project execution",
		],
		skills: [
			"Project Management",
			"Superior communication skills",
			"Design and Estimate",
			"AutoCAD",
			"Leadership",
			"Teamwork",
			"Problem-solving",
			"Critical thinking",
			"Time management",
		],
	},
];

export const education = [
	{
		position: "Full Stack Development",
		date: "December 2022 - February 2023",
		description: [
			"Completed a Full Stack Development Course, gaining hands-on experience in developing modern web applications.",
			"Developed an e-commerce API for the backend as a capstone project, showcasing complex and scalable system creation abilities.",
			"Recognized by instructors and peers as the best capstone for the backend and praised for technical skills and attention to detail.",
		],
	},
	{
		position: "Introduction to UI/UX Design",
		date: "October 2022",
		description: [
			"Completed a UI/UX Design Course in Coursera",
			"Learned the basics of UI/UX Design",
			"Learned the basics of Figma",
		],
	},
	{
		position: "Bachelor of Science in Agricultural and Biosystems Engineering",
		date: "June 2013 - April 2018",
		description: [
			"Solid foundation in the principles and practices of both agriculture and engineering.",
			"Unique perspective on the interplay between technology and the natural world.",
			"Valuable skills that can be applied to software engineering.",
		],
	},
];
