import React from "react";
import { Accordion } from "react-bootstrap";

function ExperienceCard({ id, position, date, description, skills }) {
	return (
		<Accordion defaultActiveKey={0} flush data-aos='fade-down'>
			<Accordion.Item eventKey={id}>
				<Accordion.Header className='font-weight-bold'>
					{position}
				</Accordion.Header>
				<Accordion.Body>
					<h6>
						<em>{date}</em>
					</h6>
					<ul className='text-justify my-3'>
						{description.map((item, index) => {
							return <li key={index}>{item}</li>;
						})}
					</ul>
					<h6>{skills && "Skills:"}</h6>

					<ul className='text-justify my-3'>
						{skills &&
							skills.map((item, index) => {
								return <li key={index}>{item}</li>;
							})}
					</ul>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}

export default ExperienceCard;
